import { useEffect, useRef, useState } from "react";
// import PrimaryButton from "../common/PrimaryButton";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, TextInput } from "@mantine/core";
import GoogleReCaptcha from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

const BecomeBitSqr = (props) => {
  const [recaptchaResponse, setRecaptchaResponse] = useState<any>("");
  const [lang, setLang] = useState<any>("");
  // const [modal, setModal] = useState(false);

  const subSuccess = () => {
    return (
      toast.success("Thank You For Subscribing!"),
      {
        position: "top-right",
        theme: "dark",
      }
    );
  };
  useEffect(() => {
    const currentlanguage: any = localStorage.getItem("language");
    setLang(currentlanguage);
  }, []);
  const navigate = useNavigate();

  const subError = () => {
    return (
      toast.error("Error!! Could not Subscribe.."),
      {
        position: "top-right",
      }
    );
  };
  const handleRecaptchaChange = (response: any) => {
    setRecaptchaResponse(response);
  };
  const form = useRef<any>();
  const sendEmail = (e) => {
    e.preventDefault();
    if (recaptchaResponse) {
      emailjs
        .sendForm(
          "service_d01qlic",
          "template_qmxs60f",
          form.current,
          "BnDCz_A8Hpd9d8HqA"
          // "user_UHpNJFij8MtQD1aAfs38X"
        )
        .then(
          (result) => {
            subSuccess();
            navigate("/welcome");
            e.target.reset();
            // setModal(true);
          },
          (error) => {
            subError();
          }
        );
    }
  };
  const curve = {
    borderRadius: "10px",
    width: "100%",
  };
  // console.log({ modal });

  return (
    <main className={"bg-primary becoming-square-container "} id={"subscribe"}>
      <div className={"font-bolder text-xl text-center pb-2 text-white"}>
        {props.t("become")}
      </div>
      <div className={"font-light text-md text-center pb-2 text-white"}>
        {props.t("email")}
      </div>

      <div className={"flex justify-center full-width "}>
        <form className={"w-full  "} onSubmit={sendEmail} ref={form}>
          <section className={"grid grid-cols-1 lg:grid-cols-2  gap-2 w-full "}>
            <div className={" "}>
              <TextInput
                styles={{ input: curve }}
                placeholder={lang == "en" ? "First Name" : "Nome"}
                name="first_name"
                required
              />
            </div>
            <div className={" "}>
              <TextInput
                styles={{ input: curve }}
                placeholder={lang == "en" ? "Last Name" : "Cognome"}
                name="last_name"
                required
              />
            </div>
            <div className={" "}>
              <TextInput
                styles={{ input: curve }}
                placeholder={lang == "en" ? "Profession" : "Professione"}
                name="profession"
                required
              />
            </div>
            <div className={" "}>
              <TextInput
                styles={{ input: curve }}
                placeholder={"Email"}
                name="email"
                type="email"
                required
              />
            </div>
            <div className="text-white text-md lg:text-sm flex justify-center lg:justify-end  items-center">
              {props.t("bitsquareNetwork")}
            </div>
            <div className=" flex justify-center lg:justify-end">
              <GoogleReCaptcha
                sitekey="6Lfd4N4oAAAAAD3embReJjVTZ4lAzuVfKSI8o5SY"
                onChange={handleRecaptchaChange}
              />
            </div>
            <div className="text-[#64E6C3] flex justify-center lg:justify-end text-md lg:text-sm text-start lg:text-end ">
              {props.t("50members")}
            </div>
            <div className="subBtn flex justify-center lg:justify-end lg:items-center ">
              <Button
                className="bg-[#50B4A1] hover:bg-[#459c8c] rounded-[30px] w-full lg:w-1/2 "
                placeholder={props.t("subscribe")}
                type="submit"
              >
                <div className="text-white font-semibold py-1 flex justify-center items-center">
                  {props.t("subscribe")}
                </div>
              </Button>
            </div>
          </section>

          <div className={"subscribe-button"}>
            {/* <PrimaryButton placeholder={props.t("subscribe")} /> */}
          </div>
        </form>
      </div>
      <ToastContainer />
    </main>
  );
};

export default BecomeBitSqr;
