import { useNavigate } from "react-router";
import logoImg from "../../assets/images/logo.png";

const Logo = () => {
  const navigate = useNavigate();
  return (
    <img
      src={logoImg}
      alt="logo"
      onClick={() => navigate("/")}
      style={{ cursor: "pointer" }}
    />
  );
};

export default Logo;
