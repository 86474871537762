import Logo from "./Logo";
import PrimaryButton from "../common/PrimaryButton";
import menuImg from "../../assets/images/menu.png";

const currentlanguage = () => localStorage.getItem("language");

const Navbar = (props) => {
  const choice = (e) => {
    props.language(e.target.value);
    window.location.reload();
  };

  return (
    <section
      className={
        " flex justify-between items-center dynamic-x-padding nav-padding sticky"
      }
    >
      <div className={"logo-container"}>
        <Logo />
      </div>

      <div className={"show-nav-button flex"}>
        <div className="flex align-center pr-3 ">
          <select
            className="select"
            defaultValue={
              currentlanguage() === "en"
                ? "en"
                : currentlanguage() === "it"
                ? "it"
                : "en"
            }
            onChange={(e) => choice(e)}
          >
            <option value="en">English</option>
            <option value="it">Italiano</option>
          </select>
        </div>

        <a className="subbtn" href="#subscribe">
          <PrimaryButton placeholder={props.t("subscribe")} />
        </a>
      </div>

      <div className={"show-hamburger"}>
        <img src={menuImg} alt="hamburger" />
      </div>
    </section>
  );
};
export default Navbar;
