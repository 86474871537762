import professionImg from "../../assets/images/profession.svg";
import bitsquareImg from "../../assets/images/bitsquare.svg";
import professionImgit from "../../assets/images/professionit.svg";

const Profession = (props) => {
  return (
    <main>
      <div className={"text-primary font-bolder text-lg text-center pt-2"}>
        {props.t("whateverprofession")}
      </div>

      <div className={"pt-3 profession-img-container "}>
        <img
          src={props.t("image2") == "en" ? professionImg : professionImgit}
          alt="profession"
        />
      </div>

      <div className={"flex justify-center pt-1 profession-secondary-section "}>
        <div className={"flex  items-center"}>
          <div className={"profession-bitsquare-img-container pr-3"}>
            <img src={bitsquareImg} alt="bitsquare" />
          </div>
          <div className={"font-bolder text-primary text-lg pb-50"}>
            {props.t("isright")}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profession;
