// eslint-disable-next-line react/prop-types
const PrimaryButton = ({ placeholder }) => {
  return (
    <>
      <button className={"primary-button"} type="submit">
        {placeholder}
      </button>
    </>
  );
};

export default PrimaryButton;
