import stepsImg from '../../assets/images/steps.svg';
import stepsImgit from '../../assets/images/stepsit.svg';

const StepsToBuild = (props) => {
  return (
    <div className={'step-to-build-container dynamic-x-padding bg-ternary'}>
      <div className={'font-bolder text-center text-primary text-lg pb-4 '}>{props.t('steps')}</div>

      <div>
        <img
          src={props.t('image2') === 'en' ? stepsImg : stepsImgit}
          alt="steps to build your square"
        />
      </div>
    </div>
  );
};

export default StepsToBuild;
