import heroSectionImg from "../../assets/images/heroSectionImg.svg";
import bookImg from "../../assets/images/book.svg";
import customersImg from "../../assets/images/customers.svg";
import downArrowImg from "../../assets/images/down.svg";
import { Button } from "@mantine/core";

const HeroSection = (props) => {
    return (
        <>
            <header
                className={
                    "flex justify-center  full-width dynamic-hero-section-initial"
                }
            >
                <div className={"text-center"}>
                    <div className={"text-primary font-bolder text-xl pb-1"}>
                        {props.t("build-square")}
                    </div>
                    <div className={"text-md text-secondary"}>
                        <span>{props.t("bitsquareistheapp")}</span>
                        <strong>{props.t("skills")}</strong>
                        <strong>{props.t("expertise")}</strong>
                        <strong>{props.t("know-how")}</strong>
                        <span>{props.t("Or")}</span>
                    </div>
                    <div className={"text-md text-secondary"}>
                        <div className={"text-primary "}>
                            <span>{props.t("bitsquarers")}</span>
                            <strong>{props.t("fanbase")}</strong>
                            <span>{props.t("and")}</span>
                            <strong>{props.t("community")}</strong>
                            <span>{props.t("into income")}</span>
                        </div>
                    </div>
                </div>
            </header>
            <main className={"relative  dynamic-hero-section-initial"}>
                <section className="flex justify-center">
                    <div
                        className={
                            "flex justify-center hero-section-img-container"
                        }
                    >
                        <img src={heroSectionImg} alt="heroImage" />
                    </div>
                </section>

                <section className={"header"}>
                    <article className={"flex justify-between"}>
                        <div className={"text-center "}>
                            <div className={"hero-secondary-img"}></div>
                            {/* <div
                                className={
                                    "font-bold text-primary text-lg pb-1"
                                }
                            >
                                {props.t("bitsquarer")}
                            </div> */}
                            <div className={"text-md text-secondary"}>
                                <strong>{props.t("bitsquarer")}</strong>
                                <span>{props.t("anyone")}</span>
                            </div>

                            <div className={"text-md text-secondary"}>
                                {props.t("anyone2")}
                            </div>
                            <div className={"text-md text-secondary"}>
                                <span>{props.t("anyone3")}</span>
                                <strong>{props.t("paid")}</strong>
                                <span>{props.t("wheather")}</span>
                            </div>
                            <div className={"text-md text-secondary"}>
                                {props.t("monetize")}
                            </div>
                        </div>
                        <div className={"text-center "}>
                            <div className={"hero-secondary-img"}></div>
                            {/* <div
                                className={
                                    "font-bold text-primary text-lg pb-1"
                                }
                            >
                                {props.t("customernadfollower")}
                            </div> */}
                            <div className={"text-md text-secondary"}>
                                {props.t("choosesession")}
                            </div>
                            <div className={"text-md text-secondary"}>
                                {props.t("agenda")}
                            </div>
                            <div className={"text-md text-secondary"}>
                                {props.t("agenda2")}
                            </div>
                            <div className={"text-md text-secondary"}>
                                <span>{props.t("you")}</span>
                                <strong>{props.t("whatisbest")}</strong>
                            </div>
                            <div className={"text-md text-secondary"}>
                                <strong>{props.t("onlyInItalian")}</strong>
                            </div>
                        </div>
                    </article>
                </section>
            </main>

            <main className={"dynamic-hero-section-small-screen"}>
                <section>
                    <div className={"text-center dynamic-y-padding"}>
                        <div className={"hero-secondary-img"}></div>
                        <div className={"text-md text-secondary"}>
                            <strong>{props.t("bitsquarer")}</strong>
                            <span>{props.t("anyone")}</span>
                        </div>

                        <div className={"text-md text-secondary"}>
                            {props.t("anyone2")}
                        </div>
                        <div className={"text-md text-secondary"}>
                            <span>{props.t("anyone3")}</span>
                            <strong>{props.t("paid")}</strong>
                            <span>{props.t("wheather")}</span>
                        </div>
                        <div className={"text-md text-secondary"}>
                            {props.t("monetize")}
                        </div>
                    </div>

                    <div className={"text-center"}>
                        <div className={"hero-secondary-img"}></div>
                        <div className={"text-md text-secondary"}>
                            {props.t("choosesession")}
                        </div>
                        <div className={"text-md text-secondary"}>
                            {props.t("agenda")}
                        </div>
                        <div className={"text-md text-secondary"}>
                            {props.t("agenda2")}
                        </div>
                        <div className={"text-md text-secondary"}>
                            <span>{props.t("you")}</span>
                            <strong>{props.t("whatisbest")}</strong>
                        </div>
                        <div className={"text-md text-secondary"}>
                            <strong>{props.t("onlyInItalian")}</strong>
                        </div>
                    </div>
                </section>
            </main>

            <section className={"text-md"}>
                <div className={"flex column items-center "}>
                    <div className={"down-arrow-img-container"}>
                        <a href="#subscribe">
                            <img
                                src={downArrowImg}
                                alt="down arrow"
                                className={"cursor-pointer"}
                            />
                        </a>
                    </div>
                    <div>
                        <a href="#subscribe">
                            <Button className="bg-[#153D5F] rounded-[30px] px-5 py-2 ">
                                <div className="text-white font-semibold ">
                                    {props.t("button")}
                                </div>
                            </Button>
                        </a>
                    </div>
                    {/* <div className={"text-secondary pt-1"}>{props.t("session")}</div>
          <div className="text-primary">
            <span className={"font-bold"}>{props.t("online")}</span>
            <span className="text-secondary"> {props.t("or")}</span>
            <span className={"font-bold"}> {props.t("inperson")}</span>
          </div> */}
                </div>
            </section>
        </>
    );
};

export default HeroSection;
