import Landing from "../pages/Landing";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Privacy from "../pages/Privacy";
import Navbar from "../components/partials/Navbar";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "i18next";
import Footer from "../components/partials/Footer";
import Popup from "../pages/popup";

const MainLayout = () => {
    const { t } = useTranslation();

    const changlang = (l) => {
        i18n.changeLanguage(l);
        localStorage.setItem("language", l);
    };

    useEffect(() => {
        const currentlanguage: any = localStorage.getItem("language");
        i18n.changeLanguage(currentlanguage);
    }, []);
    return (
        <main style={{ display: "flex", justifyContent: "center" }}>
            <div>
                <BrowserRouter>
                    {/* <Navbar t={t} language={changlang} /> */}

                    <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route
                            path="/privacy"
                            element={<Privacy t={t} language={changlang} />}
                        />
                        <Route
                            path="/welcome"
                            element={<Popup t={t} language={changlang} />}
                        />
                    </Routes>
                </BrowserRouter>
            </div>
        </main>
    );
};

export default MainLayout;
