import { Button } from "@mantine/core";
import Footer from "../components/partials/Footer";

const Popup = ({ t, changlang }: any) => {
  return (
    <main className=" pt-20  w-[100vw] flex justify-center items-center flex-col px-1">
      <section className="text-lg font-semibold text-[#153D5F] text-center">
        {t("thank")}
      </section>
      <section className="lg:w-1/2 sm:w-5/6 px-3 md:px-0 ">
        <section className="text-[#143D5E] pt-5  flex text-md md:text-lg ">
          <div>
            {t("toComplete")}{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.ktmbees.bit_square"
              className="font-semibold text-[#153D5F] px-1"
            >
              BitSquare
            </a>
          </div>
        </section>

        <section className="text-[#143D5E]  text-md md:text-lg pt-5 ">
          <div>{t("step")}</div>
          <ul>
            <li>{t("step1")}</li>
            <li>{t("step2")}</li>
            <li>{t("step3")}</li>
            <li>{t("step4")}</li>
          </ul>
        </section>
      </section>

      <section className=" pt-5 sm:pt-10 flex justify-center">
        <a href="/">
          <Button className="bg-[#153D5F] rounded-[30px] px-5 py-2 ">
            <div className="text-white font-semibold text-md md:text-lg ">
              {t("return")}
            </div>
          </Button>
        </a>
      </section>
      <section className="w-full bottom-0 absolute pt-5 sm:pt-0 ">
        <Footer t={t} language={changlang} />
      </section>
    </main>
  );
};

export default Popup;
