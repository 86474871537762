import whyConnectImg from "../../assets/images/whyConnect.svg";
import clockImg from "../../assets/images/clock.svg";
import calendarImg from "../../assets/images/calendar.svg";
import receiptImg from "../../assets/images/receipt.svg";
import dollarImg from "../../assets/images/dollar.svg";
import searchImg from "../../assets/images/search.svg";
import notificationImg from "../../assets/images/notification.svg";

const WhyConnect = (props) => {
  return (
    <main className={"flex justify-center items-center no-wrap font-mobile  "}>
      <div className={"pr-1 why-connect-img-container"}>
        <img src={whyConnectImg} alt="bitsquare img" />
      </div>
      <div className={"connect-with-bitsquare pr-4"}>
        <div>
          <div
            className={
              "text-primary font-bolder text-xl pb-2 connection-main-text"
            }
          >
            {props.t("whyconnect")}
          </div>
          <section className={"flex no-wrap text-md justify-between "}>
            <div className={"pr-10 connect-sub-section  "}>
              <div
                className={
                  "text-primary  font-medium text-md pb-50 bitsquare-text"
                }
              >
                {props.t("bitsqr")}
              </div>
              <div className={"flex no-wrap items-center pb-1"}>
                <div>
                  <img
                    src={clockImg}
                    alt="sessions"
                    className={"connect-icons"}
                  />
                </div>
                <div
                  className={"connect-line-height text-secondary font-light"}
                >
                  <div>{props.t("organize")}</div>
                  <div>{props.t("inpersonsession")}</div>
                </div>
              </div>

              <div className={"flex  no-wrap items-center  pb-1"}>
                <div>
                  <img
                    src={calendarImg}
                    alt="sessions"
                    className={"connect-icons "}
                  />
                </div>
                <div
                  className={"connect-line-height text-secondary font-light "}
                >
                  <div>{props.t("plan")}</div>
                  <div>{props.t("digital")}</div>
                </div>
              </div>

              <div className={"flex no-wrap items-center "}>
                <div>
                  <img
                    src={receiptImg}
                    alt="sessions"
                    className={"connect-icons "}
                  />
                </div>
                <div
                  className={"connect-line-height  text-secondary font-light"}
                >
                  <div>{props.t("recieve")}</div>
                  <div>{props.t("payments")}</div>
                </div>
              </div>
            </div>
            <div className={"connect-sub-section "}>
              <div
                className={
                  "text-primary font-medium text-md pb-50 customer-text"
                }
              >
                {props.t("customernadfollower1")}
              </div>
              <div className={"flex no-wrap items-center  pb-1 "}>
                <div>
                  <img
                    src={dollarImg}
                    alt="sessions"
                    className={"connect-icons"}
                  />
                </div>
                <div
                  className={"connect-line-height text-secondary font-light"}
                >
                  <div>{props.t("price")}</div>
                </div>
              </div>

              <div className={"flex items-center no-wrap pb-1"}>
                <div>
                  <img
                    src={searchImg}
                    alt="sessions"
                    className={"connect-icons "}
                  />
                </div>
                <div
                  className={"connect-line-height text-secondary font-light "}
                >
                  <div>{props.t("search")}</div>
                  <div>{props.t("bitsqr")}</div>
                </div>
              </div>

              <div className={"flex no-wrap items-center"}>
                <div>
                  <img
                    src={notificationImg}
                    alt="sessions"
                    className={"connect-icons "}
                  />
                </div>
                <div
                  className={"connect-line-height text-secondary font-light"}
                >
                  <div>{props.t("remainders")}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default WhyConnect;
