import { Link } from "react-router-dom";
import apple from "../../assets/images/apple.webp";
import google from "../../assets/images/google.png";

const Footer = (props) => {
    return (
        <main>
            <section
                className={
                    "dynamic-x-padding flex justify-between items-center nav-padding text-white bg-gradient footer-text font-light w-full "
                }
            >
                <div>&copy; {props.t("2022")}</div>
                <section className="flex items-center space-x-3">
                    <Link
                        to="https://apps.apple.com/np/app/bitsquare/id6497325390"
                        className="w-24 cursor-pointer"
                    >
                        <img src={apple} alt="" />
                    </Link>
                    <Link
                        to="https://play.google.com/store/apps/details?id=com.ktmbees.bitsquare"
                        className="w-24 cursor-pointer"
                    >
                        <img src={google} alt="" />
                    </Link>
                </section>
                <Link to="/privacy" className="font-light text-white">
                    Privacy Policy
                </Link>

                <div>
                    {props.t("contact")}
                    <span className={"font-bold"}> info@bitsquare.app</span>
                </div>
            </section>
        </main>
    );
};

export default Footer;
